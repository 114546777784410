<template>
  <div class>
    <div class="erp-form__header header-bar__sticky op-16" style="margin-bottom: 30px">
      <div class="erp-form__title">应收删除记录</div>
      <div class="erp-form-bar">
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="claim_company">
      <el-table :data="data" border class="egrid erp-table__list erp-view__table" :show-summary="false" :summary-method="summaryFn">
        <el-table-column label="费用类型" class-name="no-pd" align="center" show-overflow-tooltip width="140">
          <template #default="scope">
            {{ filterName(scope.row.feeTypeDesc) }}
          </template>
        </el-table-column>
        <el-table-column label="金额(人民币)" class-name="no-pd" align="center" show-overflow-tooltip width="120">
          <template #default="scope">
            {{ filterName(scope.row.amountRmbDesc) }}
          </template>
        </el-table-column>
        <el-table-column label="日期" class-name="no-pd" align="center" show-overflow-tooltip width="120">
          <template #default="scope">
            {{ filterName(scope.row.arPayDate) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="arTradeCurrencyDesc && arTradeCurrencyDesc !== ''"
          :label="'金额(' + arTradeCurrencyDesc + ')'"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="120"
        >
          <template #default="scope">
            {{ filterName(scope.row.arAmountTcDesc) }}
          </template>
        </el-table-column>
        <el-table-column label="汇率" class-name="no-pd" align="center" show-overflow-tooltip width="90">
          <template #default="scope">
            {{ filterName(scope.row.arExchangeRate) }}
          </template>
        </el-table-column>
        <el-table-column label="修改后金额" class-name="no-pd" align="center" show-overflow-tooltip width="120">
          <template #default="scope">
            {{ filterName(scope.row.amountRmbAfterModifyDesc) }}
          </template>
        </el-table-column>
        <el-table-column label="备注" class-name="no-pd" align="center" show-overflow-tooltip width="210">
          <template #default="scope">
            {{ filterName(scope.row.remark) }}
          </template>
        </el-table-column>
        <el-table-column label="凭据文件" class-name="no-pd" align="center" show-overflow-tooltip width="90">
          <template #default="scope">
            <tableFileContent
              :readOnly="true"
              :fileUrlsArray="scope.row.fileVoList"
              key-name="orderArapFileName"
              key-url="orderArapFileUrl"
              key-id="orderArapFileId"
            >
            </tableFileContent>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" class-name="no-pd" align="center" show-overflow-tooltip width="170">
          <template #default="scope">
            {{ filterName(scope.row.applyTime) }}
          </template>
        </el-table-column>
        <el-table-column label="创建人" class-name="no-pd" align="center" show-overflow-tooltip width="90">
          <template #default="scope">
            {{ filterName(scope.row.applyOperator) }}
          </template>
        </el-table-column>
        <el-table-column label="审核时间" class-name="no-pd" align="center" show-overflow-tooltip width="170">
          <template #default="scope">
            {{ filterName(scope.row.auditTime) }}
          </template>
        </el-table-column>
        <el-table-column label="审核人" class-name="no-pd" align="center" show-overflow-tooltip width="90">
          <template #default="scope">
            {{ filterName(scope.row.auditOperator) }}
          </template>
        </el-table-column>
        <el-table-column label="审核说明" class-name="no-pd" align="center" show-overflow-tooltip width="210">
          <template #default="scope">
            {{ filterName(scope.row.auditDesc) }}
          </template>
        </el-table-column>
        <el-table-column label="删除人" class-name="no-pd" align="center" show-overflow-tooltip width="90">
          <template #default="scope">
            {{ filterName(scope.row.lastOperator) }}
          </template>
        </el-table-column>
        <el-table-column label="删除时间" class-name="no-pd" align="center" show-overflow-tooltip width="170">
          <template #default="scope">
            {{ filterName(scope.row.updateTime) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
export default {
  components: { tableFileContent },
  props: {
    data: {
      type: [Object, Array],
    },
    arTradeCurrencyDesc: {
      type: [String],
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    summaryFn() {
      const summaryColumns = ['合计', '-', '', '', '', '', '', '', '', '', '', '', ''];
      const data = this.data;
      let totalRmb = data.reduce((total, item) => {
        const price = Number(item.amountRmb);
        return total + price;
      }, 0);
      totalRmb = this._formatFloat(totalRmb, 2);
      summaryColumns[1] = this._thousandBitSeparator(totalRmb);
      const isNullArray = data.filter((v) => v.amountRmbAfterModify || v.amountRmbAfterModify === 0); // 判断是不是全空
      if (isNullArray.length > 0) {
        // 如果有一条不为空的那么取金额
        let totalmodifyRmb = data.reduce((total, item) => {
          const price = item.amountRmbAfterModify || item.amountRmbAfterModify === 0 ? Number(item.amountRmbAfterModify) : Number(item.amountRmb);
          return total + price;
        }, 0);
        totalmodifyRmb = this._formatFloat(totalmodifyRmb, 2);
        summaryColumns[5] = this._thousandBitSeparator(totalmodifyRmb);
      }
      return summaryColumns;
    },
  },
};
</script>
<style lang="scss" scoped></style>
