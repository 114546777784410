<template>
  <div>
    <!--订单产品信息展示-->
    <!-- 期货现货 修改应收项目 -->
    <div style="margin-top: 30px" class="order-title__bar op-16">
      <div class="erp-form__title f16">修改应收项目（待审核）</div>
      <div class="edit-btn__bar">
        <span
          class="edit-btn"
          @click="editInfo"
          v-if="
            (baseData.orderStatus === 1 || baseData.orderStatus === 4) &&
            hasPermission('erp:fmArap:saveModifyAudit')
          "
        >
          <i class="icon-bg icon-form__edit"></i>编辑
        </span>
        <span
          class="edit-btn"
          @click="cancelOrder"
          v-if="
            (baseData.orderStatus === 1 || baseData.orderStatus === 4) &&
            hasPermission('erp:fmArap:saveModifyAudit')
          "
        >
          <i class="icon-bg icon-form__del"></i>取消
        </span>
      </div>
    </div>
    <div class="wide-table-header">
      <el-table
        :data="listData.listVo"
        border
        class="egrid erp-table__list erp-view__table"
      >
        <el-table-column
          label="费用类型"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="150"
        >
          <template #default="scope">
            {{ filterName(scope.row.feeTypeDesc) }}
          </template>
        </el-table-column>
        <el-table-column
          label="金额(人民币)"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="140"
        >
          <template #default="scope">
            {{ filterName(scope.row.amountRmbDesc) }}
          </template>
        </el-table-column>

        <el-table-column
          label="日期"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="100"
        >
          <template #default="scope">
            {{ filterName(scope.row.arPayDate) }}
          </template>
        </el-table-column>
        <el-table-column
          :label="'金额(' + arTradeCurrencyDesc + ')'"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="130"
          v-if="baseData.category < 2"
        >
          <template #default="scope">
            {{ filterName(scope.row.arAmountTcDesc) }}
          </template>
        </el-table-column>
        <el-table-column
          label="汇率"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="110"
          v-if="baseData.category < 2"
        >
          <template #default="scope">
            {{ filterName(scope.row.arExchangeRate) }}
          </template>
        </el-table-column>
        <el-table-column
          label="修改金额"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="130"
        >
          <template #default="scope">
            {{ filterName(scope.row.amountRmbAfterModifyDesc) }}
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ filterName(scope.row.remark) }}
          </template>
        </el-table-column>
        <el-table-column
          label="凭据文件"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="100"
        >
          <template #default="scope">
            <tableFileContent
              :readOnly="true"
              :fileUrlsArray="scope.row.fileVoList"
              key-name="orderArapFileName"
              key-url="orderArapFileUrl"
              key-id="orderArapFileId"
            ></tableFileContent>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="180"
        >
          <template #default="scope">
            {{ filterName(scope.row.createdTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="创建人"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="180"
        >
          <template #default="scope">
            {{ filterName(scope.row.creator) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 编辑弹层-->
    <erp-drawer :visible="visible" @close="closeDialog" erp class="no-padding">
      <receivablesEditForm
        :baseData="baseData"
        :data="listData.listVo"
        :orderArapId="orderArapId"
        :version="version"
        :arTradeCurrencyDesc="arTradeCurrencyDesc"
        @cancel="closeDialog"
      ></receivablesEditForm>
    </erp-drawer>
  </div>
</template>
<script>
import receivablesEditForm from './receivablesEditForm';
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
export default {
  components: {
    receivablesEditForm,
    tableFileContent,
  },
  props: {
    baseData: { type: [Object] },
    orderArapId: { type: [String, Number] },
    arTradeCurrencyDesc: { type: [String, Number] },
    version: { type: [String, Number] },
    data: {
      // 获取传递过来的详情
      type: [Object, Array],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      visible: false,
      listData: null,
    };
  },
  created() {
    this.initData();
  },
  watch: {
    data: {
      handler() {
        this.initData();
      },
      deep: true,
    },
  },
  methods: {
    editInfo() {
      this.visible = true;
    },
    initData() {
      if (this.data) {
        this.listData = JSON.parse(JSON.stringify(this.data));
      } else {
        this.listData = {
          listVo: [],
        };
      }
    },
    closeDialog(status) {
      this.visible = false;
      if (status) {
        this.$emit('cancel', true);
      }
    },
    cancelOrder() {
      // 取消修改金额
      // 取消审核
      this.$erpConfirm('确定取消本次审核吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/orderArap/cancelModifyAudit',
            data: {
              orderArapId: this.orderArapId,
              version: this.version,
            },
            success: (res) => {
              if (res.code === 200) {
                this.$emit('cancel', true);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
  },
  filters: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
