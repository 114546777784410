<template>
  <div>
    <div class="erp-form__header op-16 header-bar__sticky">
      <div class="erp-form__title">{{ title }}</div>
      <div class="erp-form-bar">
        <el-button class="erp-primary__btn" size="small" type="primary" @click="submit">提交审核</el-button>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <el-table
      :data="formData.dtoList"
      border
      class="egrid erp-table__list erp-view__table line-height1-nobtn"
      @selection-change="handleSelectionChange"
      @select="getSelectCurrent"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="费用类型" class-name="no-pd" align="center" width="120">
        <template v-slot:header>
          <span>费用类型</span>
        </template>
        <template #default="scope">
          {{ filterName(scope.row.feeTypeDesc) }}
        </template>
      </el-table-column>
      <el-table-column label="金额(人民币)" class-name="no-pd" align="center" width="110">
        <template v-slot:header>
          <span>金额(人民币)</span>
        </template>
        <template #default="scope">
          {{ filterName(scope.row.amountRmb) }}
        </template>
      </el-table-column>
      <el-table-column label="日期" class-name="no-pd" align="center" width="129">
        <template v-slot:header>
          <span>日期</span>
        </template>
        <template #default="scope">
          <el-date-picker
            v-model="scope.row.arPayDate"
            placeholder
            style="width: 100%"
            :disabled="!scope.row.isChecked"
            prefix-icon="null"
            @change="formatDate(scope.row, 'arPayDate', $event)"
          ></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label="提货款" class-name="no-pd" align="center" width="110" v-if="title == '申请修改应付'">
        <template v-slot:header>
          <span>提货款</span>
        </template>
        <template #default="scope">
          <el-input
            v-if="scope.row.feeType === 33"
            v-model="scope.row.apPickupAmount"
            placeholder
            @blur="changeAmount($event, scope.row, 'apPickupAmount')"
            :maxlength="10"
            :disabled="!scope.row.isChecked"
          >
          </el-input>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column class-name="no-pd" align="center" width="110" v-if="baseData.category == 1">
        <template v-slot:header>
          <span class="erp-req">{{ '金额(' + arTradeCurrencyDesc + ')' }}</span>
        </template>
        <template #default="scope">
          <el-input
            v-if="scope.row.feeType === 1 || scope.row.feeType === 3"
            v-model="scope.row.arAmountTc"
            placeholder
            @blur="changeAmount($event, scope.row, 'arAmountTc')"
            :maxlength="10"
            :disabled="!scope.row.isChecked"
          >
          </el-input>
          <div v-if="scope.row.feeType !== 1 && scope.row.feeType !== 3">
            {{ filterName(scope.row.arAmountTc) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="汇率" class-name="no-pd" align="center" width="84" v-if="baseData.category == 1">
        <template v-slot:header>
          <span>汇率</span>
        </template>
        <template #default="scope">
          <el-input
            v-model="scope.row.arExchangeRate"
            placeholder
            v-if="scope.row.feeType === 1 || scope.row.feeType === 3"
            :maxlength="7"
            @blur="changeExchangeRate($event, scope.row, 'arExchangeRate')"
            :disabled="!scope.row.isChecked"
          ></el-input>
          <div v-if="scope.row.feeType !== 1 && scope.row.feeType !== 3">
            {{ filterName(scope.row.arExchangeRate) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="修改金额(人民币)" class-name="no-pd" align="center" width="90">
        <template v-slot:header>
          <span class="erp-req">修改金额(人民币)</span>
        </template>
        <template #default="scope">
          <el-input
            v-model="scope.row.amountRmbAfterModify"
            placeholder
            clearable
            :maxlength="10"
            @blur="changeAmount($event, scope.row, 'amountRmbAfterModify')"
            :disabled="!scope.row.isChecked"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="凭据文件" class-name="no-pd" align="center" width="95">
        <template #default="scope">
          <div class="fileUrl-box">
            <tableFileContent
              :maxImg="10"
              :rowIndex="scope.$index"
              :fileUrlsArray="scope.row.attachmentDtoList"
              key-name="fmFileName"
              key-url="fmFileUrl"
              key-id="fmFileId"
              @getFileUrls="getFileUrls"
              :disabledProp="!scope.row.isChecked"
            ></tableFileContent>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="备注" class-name="no-pd" align="center">
        <template #default="scope">
          <el-input v-model="scope.row.remark" placeholder :maxlength="100" :disabled="!scope.row.isChecked"></el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import { store } from '@/store';
import { formatTime } from '@/utils/publicMethods';
export default {
  components: { tableFileContent },
  props: {
    arTradeCurrencyDesc: { type: [String, Number] },
    orderArapId: { type: [String, Number] },
    version: { type: [String, Number] },
    data: {
      type: [Object, Array],
    },
    baseData: {
      type: [Object, Array],
    },
    title: {
      type: [String],
      default: '申请修改应收',
    },
  },
  computed: {
    settleType() {
      let type = this.baseData.settleType; // 1 按天计费 2包干价 3按天计费(旧)
      if (type === 3) {
        type = 1;
      }
      return type;
    },
  },
  data() {
    return {
      Options: {
        ar_fee_type_input_qhdc: store.state.dict.options.ar_fee_type_input_qhdc,
      },
      fileType: '.pdf, .doc, .docx, .jpg, .png, .gif, .txt, .xls, .xlsx',
      titleImage: '', // 图片标题
      fileNameArr: [], // 文件名称
      fileUrlArr: [], // 图片地址
      fileUrlsArray: [],
      formData: {
        dtoList: null,
        orderArapId: null,
        version: null,
        orderId: null,
      },
      selectionList: [], // 选中的修改数据
    };
  },
  created() {
    this.formData.orderArapId = this.orderArapId;
    this.formData.version = this.version;
    this.formData.orderId = this.data[0].orderId;
    const data = JSON.parse(JSON.stringify(this.data));
    // let that = this;
    data.forEach((v, i) => {
      v.isChecked = false;
      // 判断是否禁用
      if (v.fileVoList && v.fileVoList.length > 0) {
        const attachmentDtoList = [];
        v.fileVoList.forEach((file) => {
          const objData = {
            fmFileId: file.orderArapFileId,
            fmFileName: file.orderArapFileName,
            fmFileUrl: file.orderArapFileUrl,
          };
          attachmentDtoList.push(objData);
        });
        data[i].attachmentDtoList = attachmentDtoList;
      } else {
        data[i].attachmentDtoList = null;
      }
    });
    this.formData.dtoList = data;
  },
  methods: {
    // 处理时间
    formatDate(item, name, value) {
      item[name] = formatTime(value, 'YYYY-MM-DD');
    },
    getSelectCurrent(selection, row) {
      // 选中的当前行
      row.isChecked = !row.isChecked;
    },
    handleSelectionChange(selection) {
      // 选中的数据
      this.selectionList = selection;
      if (selection.length === 0) {
        this.formData.dtoList.forEach((v) => {
          v.isChecked = false;
        });
      }
      if (selection.length === this.formData.dtoList.length) {
        this.formData.dtoList.forEach((v) => {
          v.isChecked = true;
        });
      }
    },
    getFileUrls(params) {
      const { data, index } = params;
      const item = this.formData.dtoList[index];
      if (data.length > 0) {
        item.attachmentDtoList = data;
      } else {
        item.attachmentDtoList = null;
      }
    },
    changeAmount(e, item, name) {
      let value = e.target.value;
      value = value.replace(/[^-\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace(/-{2,}/g, ''); // 只保留第一个. 清除多余的
      value = value.replace('-', '$#$').replace(/-/g, '').replace('$#$', '-');
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value >= 10000000) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 7);
        }
      }
      item[name] = value ? Number(value) : null;
    },
    changeExchangeRate(e, item, name) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value >= 100) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 2);
        }
      }
      if (Number(value) === 0) {
        value = null;
      }
      item[name] = value ? Number(value) : null;
    },
    validateData() {
      const data = this.selectionList;
      let status = true;
      if (data.length === 0) {
        this.errorTip('请勾选需要修改的费用');
        return false;
      }
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (item.amountRmbAfterModify === null || item.amountRmbAfterModify === '') {
          this.errorTip('请填写所有带星号的必填项');
          status = false;
          break;
        }
        if (this.baseData.category === 1) {
          if (item.feeType === 1 || item.feeType === 3) {
            if (item.arAmountTc === null || item.arAmountTc === '') {
              this.errorTip('请填写所有带星号的必填项');
              status = false;
              break;
            }
          }
        }
        if (this.title === '申请修改应付') {
          if (item.feeType === 33) {
            if (item.apPickupAmount === null || item.apPickupAmount === '') {
              this.errorTip('监督服务费提货款必填');
              status = false;
              break;
            }
          }
        }
      }
      return status;
    },
    /**
     * 提交事件
     */
    submit() {
      const status = this.validateData();
      if (status) {
        const data = JSON.parse(JSON.stringify(this.formData));
        data.dtoList = JSON.parse(JSON.stringify(this.selectionList));
        this.ajax({
          type: 'POST',
          url: '/malicrm/orderArap/saveModifyAudit',
          data: data,
          success: (res) => {
            if (res.code === 200) {
              this.$emit('cancel', true);
            } else {
              this.errorTip(res.message);
            }
          },
        });
      }
    },
    /**
     * 确认按钮是否disabled
     * @param feeType 费用类型
     */
    isRadioDisable(feeType) {
      // 1.若录入的费用是贷款、税款、杂费或者冷库仓储费，是否同步到应付默认选择“是”
      // 若录入的费用是代理服务费，按钮禁用
      // 包干价 代理服务费："43":"代理服务费","44":"其他服务费"
      // 按天计费 代理服务费："27":"代理费","28":"代采费","33":"监督服务费","29":"海运保险费",
      // "30":"多品名（联单）费","31":"多品类（拼单）费","32":"多次提货费", "44":"其他服务费"
      let { settleType } = this.baseData; // 1 按天计费 2包干价 3按天计费(旧)
      if (settleType === 3) {
        settleType = 1;
      }
      let feeTypeArr = [];
      if (Number(settleType) === 1) {
        feeTypeArr = [27, 28, 33, 29, 30, 31, 32, 44];
      }
      if (Number(settleType) === 2) {
        feeTypeArr = [43, 44];
      }
      if (feeTypeArr.includes(Number(feeType))) {
        return true;
      } else {
        return false;
      }
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
  filters: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
